<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- RUoli -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Configurazione SMTP</span>
      </template>
      <b-card>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75 mt-75 d-block"
          @click="$refs.refInputEl.$el.click()"
        >
          {{ $t('personalProfile.general.uploadButton') }}
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="fileXML"
          accept=".xml, .DefXml, .csv"
          :hidden="true"
          plain
        />
        <b-button
          @click="readFile"
        >converti</b-button>
        <!-- {{ fileXML }}
        {{ file }} -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75 mt-75 d-block"
          @click="convertToJSON"
        >
          XML TO JSON
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75 mt-75 d-block"
          @click="convertCSVTOJSON"
        >
          CSV TO JSON
        </b-button>
        {{ toJson }}
        <div v-if="'EasyfattDocuments' in toJson">
          {{ toJson.EasyfattDocuments.Documents.Document.Rows }}
        </div>
        <div v-if="csvToJson.length > 0">
          {{ csvToJson }}
        </div>
      </b-card>
    </b-tab>

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Moduli</span>
      </template>
      <b-card>Moduli content</b-card>
    </b-tab>
    <!--/ change password tab -->
  </b-tabs>
</template>

<script>
/* eslint-disable */

/* eslint-disable global-require */
import {
  BTabs, BTab, BCard, BButton, BFormFile,
} from 'bootstrap-vue'
import parseXml from '@core/fromXMLtoJson/parseXml'
import xml2json from '@core/fromXMLtoJson/xml2json'
import json2xml from '@core/fromXMLtoJson/json2xml'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    BButton,
    BFormFile,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      excelToJSON: [],
      csvToJson: [],
      fileXML: null,
      inputFile: '',
      file: null,
      toJson: {},
    }
  },
  beforeCreate() {},
  beforeMount() {},
  computed: {
    resultFromXMLtoString() {
      return this.inputFile
    },
  },
  methods: {
    convertCSVTOJSON() {
      this.csvToJson = this.csvJSON(this.inputFile)
    },
    convertToJSON() {
      /* const xml = '<e name="dasdas">text</e>'
      const json = xml2json(dom)
      const xml2 = json2xml(eval(json))
      console.log(xml2)
      console.log(json) */
      const dom = parseXml(this.inputFile)

      let json = xml2json(dom)
      // json = json.substring(1, 11)
      const removeUndefinedFromString = json.split('undefined')
      this.toJson = removeUndefinedFromString[0] + removeUndefinedFromString[1]
      this.toJson = JSON.parse(this.toJson)
      console.log(this.toJson)
      // console.log('provaaa', this.toJson.EasyfattDocuments.Documents.Document.Rows)
    },
    readFile() {
      const reader = new FileReader()
      this.file = this.$refs.refInputEl.files[0]
      // this.$refs.refInputEl.value = null
      reader.onload = res => {
        res.target.value = res.target.result
        // If JSON then perform JSON.stringify so file contents are not displayed as [object][object]
        this.inputFile = JSON.stringify(res.target.result, undefined, '\t')
        // If XML then do not perform anything and directly load the contents to the textarea
        this.inputFile = res.target.result
      }
      reader.onerror = err => {
        // console.log('Error : ', err)
        return err
      }
      reader.readAsText(this.file)
    },
    csvJSON(csv) {
      var lines=csv.split("\n");
      var result = [];
      var headers=lines[0].split(";");
      for(var i=1;i<lines.length;i++){
    	  var obj = {};
	      var currentline=lines[i].split(";");
        for(var j=0;j<headers.length;j++){
          obj[headers[j]] = currentline[j];
        }
	    result.push(obj);
    }  
    //return result; //JavaScript object
    return result //JSON
    },
  },
}
</script>
